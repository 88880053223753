import React, { Component } from 'react'

export default class NotAccess extends Component {
  render() {
    return (
      <section>
        <h2>No Access</h2>
        <p>You role does not have access to this page.</p>
      </section>
    )
  }
}
